import React from 'react';
import whatsapp from '../../assets/whatsapp.jpg';
import callus from '../../assets/callus.png';
import './Contact.css';

const Contact = () => {
  return (
    <div>
        <div className='whatsapp'>
            <a href='https://wa.me/919561926997' title='Whatsapp' rel="noreferrer" target='_blank'>
                <img className='whatsappimg' src={whatsapp} alt='Whatsapp Contact'/>
            </a>
        </div>
        <div className='callus'>
        <a href='tel:+919561926997' title=' Call Us: +919561926997' rel="noreferrer" target='_blank'>
            <img className='callusimg' src={callus} alt='Whatsapp Contact'/>
        </a>
    </div>
    </div>
  )
}

export default Contact;