import React from 'react'
import './Products.css';
import p1 from '../../assets/p1.png';
import p2 from '../../assets/p2.png';
import p3 from '../../assets/p3.png';
import p4 from '../../assets/p4.png';
import p5 from '../../assets/p5.png';
import p6 from '../../assets/p6.png';
import p7 from '../../assets/p7.png';

const Products = () => {
  return (
    
<div className="slider">
	<div className="slide-track">
		<div className="slide">
			<img className='product' src={p1}  alt="" />
		</div>
		<div class="slide">
			<img className='product' src={p7}  alt="" />
		</div>
		<div class="slide">
			<img className='product' src={p6} alt="" />
		</div>
		<div class="slide">
			<img className='product' src={p1} alt="" />
		</div>
		<div class="slide">
			<img className='product' src={p5} alt="" />
		</div>
		<div class="slide">
			<img className='product' src={p6} alt="" />
		</div>
		<div class="slide">
			<img className='product' src={p7} alt="" />
		</div>
		<div className="slide">
			<img className='product' src={p1}  alt="" />
		</div>
		<div class="slide">
			<img className='product' src={p7}  alt="" />
		</div>
		<div class="slide">
			<img className='product' src={p6} alt="" />
		</div>
		<div class="slide">
			<img className='product' src={p1} alt="" />
		</div>
		<div class="slide">
			<img className='product' src={p5} alt="" />
		</div>
		<div class="slide">
			<img className='product' src={p6} alt="" />
		</div>
		<div class="slide">
			<img className='product' src={p7} alt="" />
		</div>
	</div>
</div>

  )
}

export default Products;