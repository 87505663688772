import './App.css';
import Contact from './Components/Contacts/Contact';
import Footer from './Components/Footer/Footer';
import Hero from './Components/Hero/Hero.jsx';
import Join from './Components/Join/Join';
import Plans from './Components/Plans/Plans';
import Products from './Components/Products/Products';
import Programs from './Components/Programs/Programs.jsx';
import Reason from './Components/Reason/Reason.jsx';
import ScrollToTop from './Components/ScrollToTop/ScrollToTop';
import Testimonial from './Components/Testimonial/Testimonial';

function App() {
  return (
    <div className="App">
    <ScrollToTop showBelow={250}/>
      <Hero/>
      <Products/>
      <Programs/>
      <Reason/>
      <Plans/>
      <Testimonial/>
      <Join/>
      <Footer/>
      <Contact/>
      
    </div>
  );
}

export default App;
