import React, { useRef } from 'react'
import './Join.css';
import emailjs from '@emailjs/browser';

const Join = () => {
    const form=useRef()

    const sendEmail = (e) => {
        e.preventDefault();
        emailjs.sendForm('service_qu9o1ku', 'template_mcuub1j', e.target, 'hh0sSojGjwCqRVx5y')
          .then((result) => {
              console.log(result.text);
          }, (error) => {
              console.log(error.text);
          });
          e.target.reset();
      };
  return (
    <div className="Join" id='join-us'>
        <div className="left-j">
            <hr/>
            <div>
                <span className='stroke-text'>Ready To</span>
                <span>Level Up</span>
            </div>
            <div>
                <span >Your Body</span>
                <span className='stroke-text'>With Us</span>?
            </div>
        </div>


        <div className="right-j">
            <form ref={form} onSubmit={sendEmail} className="email-container">
                <input type='email' name='user_email' placeholder='Enter your Email Id'/>
                <button className='btn btn-j'>Join Now</button>
            </form>
        </div>
    </div>
  )
}

export default Join