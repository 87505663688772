import React, { useState, useEffect } from "react";
import toparrow from '../../assets/toparrow.png';
import './ScrollToTop.css';


const ScrollToTop =({showBelow})=>{
  const [show,setShow]=useState(showBelow?false:true);
  const handleScroll=()=>{
    if(window.pageYOffset>showBelow){
      if(!show)setShow(true)
    }else{
      if(show)setShow(false)
    }
  }
  useEffect(()=>{
    if(showBelow){
      window.addEventListener('scroll',handleScroll)
      return()=> window.removeEventListener('scroll',handleScroll)
    }
  })
  const handleClick=()=>{
    window['scrollTo']({top:0, behavior:'smooth'})}
  
  return(
    <div className='topArrow'>
      {show && 
      
        <img src={toparrow} 
        onClick={handleClick}  
        className='topArrowimg' title='Go UP' alt=''/>
      
      }
    </div>
  )
}
export default ScrollToTop;